<template>
  <div class="bac">
    <el-card shadow="always" :v-loading="applys" style="width: 14rem;">
      <el-table
          :data="applys"
          highlight-current-row
          max-height="200"
          :header-cell-style="{'text-align':'center'}"
          :cell-style="{'text-align':'center'}">
        <el-table-column label="公司名称" prop="companyName"></el-table-column>
        <el-table-column label="联系人" prop="linkName"></el-table-column>
        <el-table-column label="电话" prop="linkPhone"></el-table-column>
        <el-table-column label="预算" prop="budget"></el-table-column>
        <el-table-column label="操作" >
          <template #default="scope">
            <el-button type="danger" @click="show(scope.row)">查看</el-button>
            <el-button type="danger" @click="del(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          background
          layout="prev, pager, next"
          :total="info.total"
          v-model:current-page="info.currentPage"
          v-model:page-size="info.size"
          style=";--el-pagination-hover-color: #ffffff;"/>
    </el-card>
    <el-dialog
        v-model="dialogVisible"
        title="查看"
        width="80%"
    >
      <el-descriptions
          class="margin-top"
          title="详细信息"
          :column="4"
          border
      >
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">
              <el-icon :style="iconStyle">
                <user />
              </el-icon>
              联系人
            </div>
          </template>
          {{showForm.linkName}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">
              <el-icon :style="iconStyle">
                <iphone />
              </el-icon>
              电话
            </div>
          </template>
          {{showForm.linkPhone}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">
              <el-icon :style="iconStyle">
                <location />
              </el-icon>
              预算
            </div>
          </template>
          {{showForm.budget}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">
              <el-icon :style="iconStyle">
                <tickets />
              </el-icon>
              微信
            </div>
          </template>
          {{showForm.linkWx}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">
              邮箱
            </div>
          </template>
          {{showForm.linkEmail}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">
              行业
            </div>
          </template>
          {{showForm.industry}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">
              <el-icon :style="iconStyle">
                <office-building />
              </el-icon>
              公司地址
            </div>
          </template>
          {{showForm.companyAddress}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">
              <el-icon :style="iconStyle">
                <office-building />
              </el-icon>
              公司名称
            </div>
          </template>
          {{showForm.companyName}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">
              描述
            </div>
          </template>
          {{showForm.demandDscp}}
        </el-descriptions-item>
      </el-descriptions>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">关闭</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "SignList",
  data(){
    return{
      dialogVisible: false,
      info:{
        currentPage:'',
        size:30,
        total:0,
      },
      applys:[],
      showForm:{
        budget: '',
        companyAddress: "",
        companyName: "",
        createTime: "",
        demandDscp: "",
        industry: "",
        linkEmail: "",
        linkName: "",
        linkPhone: "",
        linkWx: "",
        status: 0
      }
    }
  },
  mounted() {
    this.getData();
  },
  methods:{
    getData(){
      this.$http({
        method:'get',
        url:'/activity/apply/searchAll?currentPage=' + this.info.currentPage + '&&size=' + this.info.size
      }).then(({data})=>{
        console.log(data);
        this.info.total = data.total;
        this.applys = data.data.data;
      })
    },
    show(row){
      this.showForm = row;
      this.dialogVisible = true;
    },
    del(id){
      this.$http({
        method:'delete',
        url:'/activity/apply/delete',
        params:{
          id:id
        }
      }).then(({data})=>{
        if(data.code===200){
          this.getData();
        }
      })
    }
  }
}
</script>

<style scoped>
.bac{
  height: 10rem;
}
</style>
